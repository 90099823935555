import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
})
export class PercentagePipe implements PipeTransform {
  transform(value: number, digits = 2): any {
    return (value || 0).toFixed(digits);
  }
}
