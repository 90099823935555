import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-nav-card-full',
  templateUrl: './nav-card-full.component.html',
  styleUrls: ['./nav-card-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardFullComponent {
  constructor() {}
}
