import { Component, Input } from '@angular/core';

@Component({
  selector: 'mspot-data-loading',
  templateUrl: './data-loading.component.html',
  styleUrls: ['./data-loading.component.scss'],
  host: {
    class: 'flex flex-col items-center justify-center my-6',
  },
})
export class DataLoadingNewComponent {
  @Input() loading = true;

  @Input() heading: string | null | undefined = 'common.loading';
}
