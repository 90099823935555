import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

@Pipe({
  name: 'fsDate',
})
export class FsDatePipe extends DatePipe implements PipeTransform {
  override transform(value: any | Timestamp | Date, ...args: any[]): any {
    if (value && !(value instanceof Date) && value.toDate) {
      return super.transform(value.toDate(), 'mediumDate');
    } else if (typeof value === 'number' || typeof value === 'string') {
      return super.transform(value, 'mediumDate');
    } else {
      return '';
    }
  }
}
