import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objArr',
})
export class ObjectArrayPipe implements PipeTransform {
  transform(obj: Record<string, any> | undefined, args?: string): any[];
  transform<T extends Record<string, T>>(
    obj?: Record<string, T>,
    args?: string,
  ): T[] {
    if (!obj) {
      return [];
    }

    let arr = Object.values(obj);

    if (args) {
      arr = arr.sort((a: any, b: any) => (a[args] || 0) - (b[args] || 0));
    }

    return arr;
  }
}
