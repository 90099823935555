import { Directive } from '@angular/core';

@Directive({
  selector: 'button[mspot-button--white], a[mspot-button--white])',
  host: {
    class: 'btn--icon btn-white',
    type: 'button',
  },
})
export class WhiteButtonDirective {
  constructor() {}
}
