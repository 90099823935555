import { Directive } from '@angular/core';

@Directive({
  selector: 'button[mspot-button--primary])',
  host: {
    class: 'btn--icon btn-primary',
    type: 'button',
  },
})
export class PrimaryButtonDirective {
  constructor() {}
}

@Directive({
  selector:
    'button[mspot-basic-button--primary], a[mspot-basic-button--primary])',
  host: {
    class: 'btn--icon btn-primary--basic',
    type: 'button',
  },
})
export class PrimaryBasicButtonDirective {
  constructor() {}
}
