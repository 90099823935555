import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinutes',
  standalone: true,
})
export class SecondsToMinutesPipe implements PipeTransform {
  transform(value: number): unknown {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);

    return `${minutes > 9 ? minutes : '0' + minutes.toString()}:${
      seconds > 9 ? seconds : '0' + seconds.toString()
    }`;
  }
}
