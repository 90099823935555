/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { mspotLinkType } from '@memberspot/frontend/shared/ui/elements';

@Component({
  selector: 'mspot-save-back',
  templateUrl: './save-back.component.html',
  styleUrls: ['./save-back.component.scss'],
  host: {
    class: 'flex flex-wrap gap-4 justify-between items-start sm:items-center',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveBackComponent {
  @Input() disableBack = false;
  @Input() noSave = false;

  @Input() saveDisabled = false;
  @Input() saving = false;
  @Input() saveText = 'common.save';
  @Input() helpLink?: mspotLinkType;
  @Input() helpText = '';

  @Input() loading = false;

  @Input() pathback = '../';
  @Input() queryParams: Record<string, string> = {};
  @Input() history = false;

  @Input() fragment?: string;

  @Output() save = new EventEmitter();

  @Input() showDelete: boolean | null = true;
  @Input() delText = 'common.delete';
  @Output() delete = new EventEmitter();

  @HostBinding('class')
  @Input()
  mtClass = 'mt-4';

  get deleteActive() {
    return this.delete.observers.length > 0 && this.showDelete;
  }

  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
