import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIconsModule } from '@ng-icons/core';
import { TranslocoModule } from '@ngneat/transloco';

import { BackButtonComponent } from './back-button/back-button.component';
import { ButtonIconDirective } from './button-icon.directive';
import {
  GrayButtonDirective,
  GrayButtonLinkDirective,
  GrayStrokedButtonDirective,
  GrayStrokedButtonLinkDirective,
} from './buttons/gray-button.directive';
import {
  PrimaryBasicButtonDirective,
  PrimaryButtonDirective,
} from './buttons/primary-button.directive';
import {
  RedButtonDirective,
  RedStrokedButtonDirective,
} from './buttons/red-button.directive';
import { WhiteButtonDirective } from './buttons/white-button.directive';
import {
  DeleteButtonComponent,
  DeleteButtonFlatComponent,
} from './delete-button/delete-button.component';
import { PulseDirective } from './directives/pulse.directive';
import { HoveredIconButtonComponent } from './hovered-icon-button/hovered-icon-button';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { NavCardBottomBackComponent } from './nav-card-bottom-back/nav-card-bottom-back.component';
import { NotificationButtonComponent } from './notification-button/notification-button.component';
import { TableActionButtonComponent } from './table-action-button/table-action-button.component';

@NgModule({
  imports: [
    CommonModule,
    NgIconsModule,
    RouterModule,
    TranslocoModule,
    TableActionButtonComponent,
    NavCardBottomBackComponent,
    GrayButtonDirective,
    GrayStrokedButtonDirective,
    GrayButtonLinkDirective,
    GrayStrokedButtonLinkDirective,
    ButtonIconDirective,
  ],
  declarations: [
    DeleteButtonComponent,
    DeleteButtonFlatComponent,

    PrimaryButtonDirective,
    BackButtonComponent,
    WhiteButtonDirective,
    PrimaryBasicButtonDirective,
    RedButtonDirective,
    RedStrokedButtonDirective,

    PulseDirective,
    MenuButtonComponent,
    NotificationButtonComponent,
    HoveredIconButtonComponent,
  ],
  exports: [
    DeleteButtonComponent,
    DeleteButtonFlatComponent,
    GrayButtonDirective,
    PrimaryButtonDirective,
    GrayStrokedButtonDirective,
    ButtonIconDirective,
    BackButtonComponent,
    WhiteButtonDirective,
    PrimaryBasicButtonDirective,
    RedButtonDirective,
    RedStrokedButtonDirective,
    GrayButtonLinkDirective,
    GrayStrokedButtonLinkDirective,
    PulseDirective,
    MenuButtonComponent,
    NavCardBottomBackComponent,
    TableActionButtonComponent,
    NotificationButtonComponent,
    HoveredIconButtonComponent,
  ],
})
export class FrontendSharedUiButtonsModule {}
