<div
  class="flex flex-col space-y-5 sm:mt-2 sm:flex-row sm:flex-wrap sm:items-center sm:space-y-0"
>
  <div class="flex flex-1 flex-col items-center justify-center sm:items-start">
    <h3 class="heading-new">
      <ng-content></ng-content>
    </h3>
    @if (subtitle) {
      <div class="subtitle-new">
        {{ subtitle }}
      </div>
    }
  </div>

  <div class="flex flex-1 items-center justify-center sm:justify-end">
    <ng-content select="[slot=end]"> </ng-content>

    @if (link) {
      <a
        data-cy="headBtn__link"
        class="btn--icon btn-gray--stroked"
        [routerLink]="link"
      >
        <ng-icon name="hero-plus-circle" class="-ml-1 mr-1.5 text-xl"></ng-icon>
        {{ text }}
      </a>
    }
    @if (!link && add.observers.length > 0) {
      <button
        class="btn--icon btn-primary"
        (click)="add.emit()"
        [disabled]="disableBtn"
      >
        <ng-icon name="hero-plus-circle" class="-ml-1 mr-1.5 text-xl"></ng-icon>
        {{ text }}
      </button>
    }

    <ng-content select="[slot=last]"> </ng-content>
  </div>
</div>
