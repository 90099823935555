import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mspot-small-spinner',
  templateUrl: './small-spinner.component.html',
  styleUrls: ['./small-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallSpinnerComponent {
  @Input() text = 'Wird geladen ...';

  constructor() {}
}
