import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'lib-save-abort',
  templateUrl: './save-abort.component.html',
  styleUrls: ['./save-abort.component.scss'],
  host: {
    class: 'flex justify-end items-center space-x-4',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveAbortComponent {
  @Input() saveDisabled = false;
  @Output() aborting = new EventEmitter();

  constructor() {}
}
