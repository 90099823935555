import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[mspot-notification-button]',
  templateUrl: './notification-button.component.html',
  styles: [],
  host: {
    type: 'button',
    class:
      'ml-5 flex justify-center client-header-icon items-center rounded-full relative',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationButtonComponent {
  @Input() showBadge: boolean | null = false;
}
