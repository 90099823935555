import { Directive } from '@angular/core';

@Directive({
  selector: '[mspotButtonIcon]',
  host: {
    class: 'text-xl mr-2 -ml-1',
  },
  standalone: true,
})
export class ButtonIconDirective {}
