import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mspot-load-spinner-new',
  templateUrl: './load-spinner-new.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadSpinnerNewComponent {
  @Input() loadingText = 'Wird geladen ...';
  @Input() size = 'h-5 w-5';
}
