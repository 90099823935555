/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[throttleClick]',
})
export class ThrottleClickDirective {
  @Output() throttleClick = fromEvent(
    this._elementRef.nativeElement,
    'click',
  ).pipe(throttleTime(500));

  constructor(private _elementRef: ElementRef) {}
}
