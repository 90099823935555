@if (loading) {
  <div class="mb-3 flex flex-col items-center justify-center">
    <div>
      <mat-spinner></mat-spinner>
    </div>
    <div class="mt-2">
      {{ 'notifications.default.isLoading' | transloco }}
    </div>
  </div>
}
