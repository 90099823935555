<ng-container *transloco="let t">
  <div class="flex flex-col flex-wrap space-x-4 sm:flex-row sm:items-center">
    <div class="order-2 flex items-center space-x-5 sm:order-2">
      @if (!disableBack && !history) {
        <a
          [routerLink]="pathback"
          [queryParams]="queryParams"
          [fragment]="fragment"
          class="btn btn-gray--stroked"
          data-cy="saveBack__back"
        >
          {{ t('common.back') }}
        </a>
      }
      @if (!disableBack && history) {
        <button
          (click)="goBack()"
          type="button"
          class="btn btn-gray--stroked"
          data-cy="saveBack__back"
        >
          {{ t('common.back') }}
        </button>
      }
      @if (!noSave) {
        <button
          data-cy="saveBack__save"
          class="btn btn-primary btn-save flex justify-around"
          (click)="save.emit()"
          [disabled]="saveDisabled || saving"
        >
          <mspot-load-btn
            class="pointer-events-none"
            [loading]="saving || loading"
            [loadingText]="saving ? t('text.isSaving') : t('text.isChecking')"
          >
            {{ t(saveText) }}
          </mspot-load-btn>
        </button>
      }
    </div>

    <div class="order-1 sm:order-2">
      <ng-content select="[slot=start]"></ng-content>
    </div>
  </div>

  <div class="flex items-center justify-start sm:justify-end">
    <ng-content></ng-content>

    @if (helpLink) {
      <mspot-help-link
        class="self-end"
        [link]="helpLink"
        [showTextMobile]="true"
        >{{ t('messages.learnMoreAbout', { text: helpText }) }}</mspot-help-link
      >
    }
    @if (deleteActive) {
      <button
        class="btn btn-red--stroked"
        type="button"
        (click)="delete.emit()"
      >
        {{ t(delText) }}
      </button>
    }
  </div>
</ng-container>
