import { Directive } from '@angular/core';

@Directive({
  selector: 'button[mspot-button--red], a[mspot-button--red])',
  host: {
    class: 'btn--icon btn-red--basic',
    type: 'button',
  },
})
export class RedButtonDirective {
  constructor() {}
}

@Directive({
  selector: 'button[mspot-stroked-button--red], a[mspot-stroked-button--red])',
  host: {
    class: 'btn--icon btn-red--stroked',
    type: 'button',
  },
})
export class RedStrokedButtonDirective {
  constructor() {}
}
