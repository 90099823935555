import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mspot-img-avatar',
  templateUrl: './img-avatar.component.html',
  styleUrls: ['./img-avatar.component.scss'],
  host: {
    class: 'flex-shrink-0',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgAvatarComponent {
  @Input() image!: string;
}
