import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-double-bounce',
  templateUrl: './double-bounce.component.html',
  styleUrls: ['./double-bounce.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoubleBounceComponent {
  constructor() {}
}
