import { Pipe, PipeTransform } from '@angular/core';
import { AllPost, UserChapterOri } from '@memberspot/shared/model/course';
import {
  exampleChapterUrl,
  getPostImgShort,
} from '@memberspot/shared/util/constants';

@Pipe({
  name: 'thumbnail',
})
export class ThumbnailPipe implements PipeTransform {
  transform(item?: AllPost | UserChapterOri | null): string | null {
    if (!item) {
      return exampleChapterUrl;
    }

    if (item?.thumbnailUrl) {
      return item.thumbnailUrl;
    }

    if ('type' in item) {
      return getPostImgShort(item);
    }

    return exampleChapterUrl;
  }
}
