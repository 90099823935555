/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Required } from '@memberspot/frontend/shared/util/required-input';

@Component({
  selector: 'button[mspot-hovered-icon-button]',
  template: `<ng-icon class="rounded-full" [name]="heroIconName"></ng-icon>`,
  styles: [],
  host: {
    type: 'button',
    class:
      'w-8 h-8 flex justify-center items-center rounded-full active:bg-gray-100 hover:bg-gray-100 text-gray-600',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoveredIconButtonComponent {
  @Input() @Required() heroIconName!: string;

  @HostBinding('class')
  @Input()
  size = 'text-xl';
}
