/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'button[mspot-button--delete])',
  templateUrl: './delete-button.component.html',
  host: {
    class: 'btn--icon btn-red--basic',
    type: 'button',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteButtonComponent {
  constructor() {}
}

@Component({
  selector: 'button[mspot-stroked-button--delete]',
  templateUrl: './delete-button.component.html',
  host: {
    class: 'btn--icon btn-red--stroked',
    type: 'button',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteButtonFlatComponent {
  constructor() {}
}
