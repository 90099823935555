<ng-container *transloco="let t">
  <div class="flex w-full flex-col gap-3 sm:flex-row">
    <button
      type="button"
      class="btn btn-gray--stroked w-full"
      (click)="aborting.emit()"
    >
      {{ t('common.abort') }}
    </button>

    <button
      class="btn btn-primary w-full"
      [disabled]="saveDisabled"
      type="submit"
    >
      {{ t('common.save') }}
    </button>
  </div>
</ng-container>
