import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { FrontendSharedUiButtonsModule } from '@memberspot/frontend/shared/ui/buttons';
import { FrontendSharedUiControlsModule } from '@memberspot/frontend/shared/ui/controls';
import { FrontendSharedUiElementsModule } from '@memberspot/frontend/shared/ui/elements';
import { FrontendSharedUiLayoutModule } from '@memberspot/frontend/shared/ui/layout';
import { FrontendSharedUtilFormModule } from '@memberspot/frontend/shared/util/form';
import { FrontendSharedUtilImageModule } from '@memberspot/frontend/shared/util/image';
import { NgIconsModule } from '@ng-icons/core';
import { TranslocoModule } from '@ngneat/transloco';

import { BackComponent } from './components/back/back.component';
import { CardComponent } from './components/card/card/card.component';
import { CardWarningHeaderComponent } from './components/card/card-warning-header/card-warning-header.component';
import { FillCardComponent } from './components/card/fill-card/fill-card.component';
import { NavCardComponent } from './components/card/nav-card/nav-card.component';
import { NavCardFullComponent } from './components/card/nav-card-full/nav-card-full.component';
import { NavCardNoContentComponent } from './components/card/nav-card-no-content/nav-card-no-content.component';
import { CustomIconsComponent } from './components/custom-icons/custom-icons.component';
import { DataLoadingComponent } from './components/data-loading/data-loading.component';
import { DataLoadingNewComponent } from './components/data-loading-new/data-loading.component';
import { DisabledFeatureComponent } from './components/disabled-feature/disabled-feature.component';
import { DividerComponent } from './components/divider/divider.component';
import { DoubleBounceComponent } from './components/double-bounce/double-bounce.component';
import { HeadBtnComponent } from './components/head-btn/head-btn.component';
import { IconTextComponent } from './components/icon-text/icon-text.component';
import { ImgAvatarComponent } from './components/img-avatar/img-avatar.component';
import { LoadBtnComponent } from './components/load-btn/load-btn.component';
import { LoadSpinnerComponent } from './components/load-spinner/load-spinner.component';
import { LoadSpinnerNew2Component } from './components/load-spinner-2/load-spinner-new.component';
import { LoadSpinnerNewComponent } from './components/load-spinner-new/load-spinner-new.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { SaveAbortComponent } from './components/save-abort/save-abort.component';
import { SaveBackComponent } from './components/save-back/save-back.component';
import { SectionBtnComponent } from './components/section-btn/section-btn.component';
import { SectionCenterComponent } from './components/section-center/section-center.component';
import { SmallSpinnerComponent } from './components/small-spinner/small-spinner.component';
import { GenTemplateDirective } from './directives/gen-template.directive';
import { HooverTextDirective } from './directives/hoover-text.directive';
import { IntersectionDirective } from './directives/intersection.directive';
import { ThrottleClickDirective } from './directives/throttle-click/throttle-click.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FileSizeAutoPipe } from './pipes/file-size-auto.pipe';
import { FsDatePipe } from './pipes/fs-date.pipe';
import { FsDateTimePipe } from './pipes/fs-date-time.pipe';
import { ObjectArrayPipe } from './pipes/object-array.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { SecondsToMinutesPipe } from './pipes/seconds-to-minutes.pipe';
import { ThumbnailPipe } from './pipes/thubmnail/thumbnail.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SquircelIconComponent } from './squircel-icon/squircel-icon.component';

const pipes = [
  FsDatePipe,
  FsDateTimePipe,
  FileSizePipe,
  FileSizeAutoPipe,
  ObjectArrayPipe,
  PercentagePipe,
  ThumbnailPipe,
];

const directives = [
  HooverTextDirective,
  GenTemplateDirective,
  IntersectionDirective,
  ThrottleClickDirective,
];

const components = [
  CardComponent,
  LoadSpinnerComponent,
  NoDataComponent,
  SaveBackComponent,
  BackComponent,
  DataLoadingComponent,
  DoubleBounceComponent,
  NavCardComponent,
  HeadBtnComponent,
  DisabledFeatureComponent,
  DividerComponent,
  SmallSpinnerComponent,
  SectionCenterComponent,
  SectionBtnComponent,
  FillCardComponent,
  NavCardFullComponent,
  NavCardNoContentComponent,
  CardWarningHeaderComponent,
  CustomIconsComponent,
  SquircelIconComponent,
  ImgAvatarComponent,
  IconTextComponent,
  LoadSpinnerNewComponent,
  SaveAbortComponent,
  DataLoadingNewComponent,
  LoadSpinnerNew2Component,
];

const materialModules = [
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatCardModule,
];

@NgModule({
  declarations: [...pipes, ...directives, ...components],
  imports: [
    TranslocoModule,
    FrontendSharedUiLayoutModule,
    FrontendSharedUiControlsModule,
    FrontendSharedUiElementsModule,
    FrontendSharedUtilFormModule,
    NgIconsModule,
    FrontendSharedUiButtonsModule,
    CommonModule,
    RouterModule,
    FrontendSharedUtilImageModule,
    TruncatePipe,
    SecondsToMinutesPipe,
    LoadBtnComponent,
    ...materialModules,
  ],
  exports: [
    ...pipes,
    ...directives,
    ...components,
    TruncatePipe,
    SecondsToMinutesPipe,
    FrontendSharedUiLayoutModule,
    FrontendSharedUiControlsModule,
    FrontendSharedUiElementsModule,
    FrontendSharedUtilFormModule,
    LoadBtnComponent,
  ],
})
export class ToolsModule {}
