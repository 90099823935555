/* eslint-disable @angular-eslint/directive-selector */
import { Directive } from '@angular/core';

@Directive({
  selector: 'button[mspot-button]',
  host: {
    class: 'btn--icon btn-gray--basic',
    type: 'button',
  },
  standalone: true,
})
export class GrayButtonDirective {}

@Directive({
  selector: 'a[mspot-button]',
  host: {
    class: 'btn--icon btn-gray--basic',
  },
  standalone: true,
})
export class GrayButtonLinkDirective {}

@Directive({
  selector: 'button[mspot-stroked-button]',
  host: {
    class: 'btn--icon btn-gray--stroked',
    type: 'button',
  },
  standalone: true,
})
export class GrayStrokedButtonDirective {}
@Directive({
  selector: 'a[mspot-stroked-button]',
  host: {
    class: 'btn--icon btn-gray--stroked',
  },
  standalone: true,
})
export class GrayStrokedButtonLinkDirective {}
