<div class="mt-4 flex flex-wrap justify-center md:justify-start">
  <button
    [mspotBack]="pathback"
    [queryParams]="queryParams"
    [fragment]="fragment"
    [textBack]="textBack"
    [history]="history"
  ></button>
  <ng-content></ng-content>
</div>
