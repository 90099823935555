import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mspot-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackComponent {
  @Input() textBack = 'Zurück';
  @Input() pathback = '../';
  @Input() queryParams: Record<string, string> = {};
  @Input() fragment?: string;
  @Input() switchAlign = false;
  @Input() history = false;
}
