import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavBackService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.router.events
      .pipe(
        filter<any, NavigationEnd>(
          (e): e is NavigationEnd => e instanceof NavigationEnd,
        ),
      )
      .subscribe((event) => {
        this.history.push(event.urlAfterRedirects);
      });
  }

  async back(emptyHandler: () => any) {
    this.history.pop();

    if (this.history.length > 0) {
      this.location.back();
    } else {
      emptyHandler();
    }
  }
}
