import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'mspot-section-btn',
  templateUrl: './section-btn.component.html',
  styleUrls: ['./section-btn.component.scss'],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionBtnComponent {
  @Output() add = new EventEmitter();

  @Input() text = 'Hinzufügen';
  @Input() link?: string;
  @Input() disableBtn = false;

  @Input() subtitle?: string;

  constructor() {}
}
