import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavBackService } from '@memberspot/frontend/shared/util/common';
import { NgIconComponent } from '@ng-icons/core';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonIconDirective } from '../button-icon.directive';
import { GrayButtonDirective } from '../buttons/gray-button.directive';

@Component({
  selector: 'mspot-bottom-back',
  template: `
    <button (click)="goBack()" mspot-button>
      <ng-icon mspotButtonIcon name="hero-arrow-left"></ng-icon>
      {{ 'common.back' | transloco }}
    </button>
  `,
  styles: [],
  host: {
    class: 'sm:px-3 lg:px-4 block',
  },
  standalone: true,
  imports: [
    NgIconComponent,
    TranslocoModule,
    GrayButtonDirective,
    ButtonIconDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardBottomBackComponent {
  @Input() link = '../';

  @Input() history = false;

  private _navBackService = inject(NavBackService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);

  async goBack() {
    if (this.history) {
      this._navBackService.back(this._goBackHandler.bind(this));
    } else {
      this._goBackHandler();
    }
  }

  private async _goBackHandler() {
    await this._router.navigate([this.link], {
      relativeTo: this._route,
      // queryParams: this.queryParams,
      // fragment: this.fragment,
    });
  }
}
