import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lib-custom-icons',
  templateUrl: './custom-icons.component.html',
  styleUrls: ['./custom-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomIconsComponent {
  @Input() icon = '';

  constructor() {}
}
