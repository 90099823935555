/* eslint-disable @angular-eslint/component-selector */
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavBackService } from '@memberspot/frontend/shared/util/common';

@Component({
  selector: 'button[mspotBack]',
  templateUrl: './back-button.component.html',
  host: {
    class: 'btn--icon ',
    type: 'button',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  @Input() textBack = 'Zurück';
  @Input() mspotBack = '../';
  @Input() queryParams?: Params | null;
  @Input() fragment?: string;
  @Input() history = true;

  @Input() @HostBinding('class') classes = 'btn-gray--stroked';

  constructor(
    private navBackService: NavBackService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  @HostListener('click')
  async goBack() {
    if (this.history) {
      this.navBackService.back(this.goBackHandler.bind(this));
    } else {
      this.goBackHandler();
    }
  }

  private async goBackHandler() {
    await this.router.navigate([this.mspotBack], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
    });
  }
}
