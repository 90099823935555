<mspot-nav-card-wrapper>
  <mspot-card class="rounded-lg">
    <ng-content select="[slot=top]"></ng-content>

    <mspot-card-content>
      <ng-content></ng-content>
    </mspot-card-content>

    <ng-content select="[slot=end]"></ng-content>
  </mspot-card>
</mspot-nav-card-wrapper>
